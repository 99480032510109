<template>
  <div>
    <div class="flex relative items-center w-full">
      <Input label="Swift code / Routing no" required p="p-2" class="mt-4 w-full"  placeholder="E.g 02334564" v-model="paymentFirstData.swiftCode" @blur="handleLookupValidation" @keyup="getLookUpData" maxlength="11"/>
      <span class="absolute bank-name text-xs font-semibold text-dark" v-if="paymentFirstData.userBank.length">{{paymentFirstData.userBank | truncate(30)}}</span>
      <Spinner class="pt-8" v-if="swiftLookupLoading"/>
    </div>
    <p class="text-brandRed text-xs" v-if="routingErrorText.length">{{routingErrorText}}</p>
    <div v-show="!swiftLookupLoading && (paymentFirstData.userBank && paymentFirstData.userBank.length)">
      <Input  class="mt-4" label="Receiving Bank’s Country" required disabled placeholder="China" v-model="bankCountry" p="p-2"/>
      <Input v-if="paymentFirstData.countrySpecificData.collectIfcs" errorText="Fill this field, characters must be 11" ref="ifscInput"  :validation="rules.financialSystemCode" :maxlength="11" label="Indian financial system code (IFSC)" required p="p-2" class="mt-4"  placeholder="E.g 8e99j" v-model="paymentFirstData.financialSystemCode"/>
      <Input label="Beneficiary’s IBAN" required p="p-2" class="mt-4"  placeholder="EGXXXXXX" v-model="paymentFirstData.accountNumber" :validation="rules.accountNumber" v-if="paymentFirstData.countrySpecificData.collectIban" :errorText="`IBAN code must be ${paymentFirstData.countrySpecificData.ibanExpectedLength} characters`" ref="ibanInput"/>
      <Input label="Beneficiary’s account number" required p="p-2" class="mt-4" number placeholder="000000" v-model="paymentFirstData.accountNumber" v-else/>
     <Input label="Beneficiary’s name" required p="p-2" class="mt-4"  placeholder="John Doe" v-model="paymentFirstData.name"/>
     <Input number errorText="Fill this field, characters must be 10" :validation="rules.businessRegistrationNumber" v-if="paymentFirstData.countrySpecificData.collectBusinessRegistrationNumber" ref="businessRegInput" class="mt-4" label="Beneficiary’s business registration number" :maxlength="10" required placeholder="E.g 0109675432" v-model="paymentFirstData.businessRegistrationNumber" p="p-2"/>
     <Input :validation="rules.email" ref="emailRef" label="Beneficiary’s email address(Optional)" p="p-2" class="mt-4"  placeholder="example@domain.com" type="email" v-model="paymentFirstData.email"/>
     <Input ref="emailRef"  :validation="rules.address" errorText="Invalid characters. Use only English letters, numbers, or symbols" label="Beneficiary’s address" required p="p-2" class="mt-4"  placeholder="Enter beneficiary's address" type="email" v-model="paymentFirstData.address" id="address"/>
      <Select z-index="auto" @input="handleSelectCountry" :reduce="country => country" :value="paymentFirstData.country" :options="generateCountryOptionList" required class="mt-4" formLabel="Beneficiary's Country" placeholder="China" p="p-2" /> 
      <Select v-if="getStateForSelectedCountry && getStateForSelectedCountry.length" placeholder="Select state" label="name" :reduce="state => state.code"  :options="getStateForSelectedCountry" formLabel="State" required class="mt-4" v-model="paymentFirstData.state" errorText="State is required"/>
      <Input :validation="rules.state" placeholder="Enter state" required label="State" class="mt-4" v-model="paymentFirstData.state" errorText="State is required" v-else/>
      <Input ref="cityInput" label="City" p="p-2" required class="mt-4" placeholder="Enter city" v-model="paymentFirstData.city" :validation="rules.city" errorText="City is required"/>
     <Input ref="postalCodeInput" :validation="rules.postalCode" errorText="Valid postal code is required" p="p-2" class="mt-4" placeholder="E.g 1000001" label="Postal code" required  v-model="paymentFirstData.postalCode"/>
     <TelInput ref="beneficiaryPhoneInput" v-if="paymentFirstData.countryCode && showTelInput" :defaultCountry="paymentFirstData.countryCode" label="Beneficiary’s Phone number" required p="p-2" class="mt-4" number  :validation="rules.phoneNumber" placeholder="(406) 000-0000" type="email" v-model="paymentFirstData.phoneNumber" @valid="isPhoneValid"/>
      <Button text="Continue"  class="mt-4" @click="handleNextStep" :disabled="disableButton" />
    </div>
   
  </div>
</template> 
<script>
  import {mapActions, mapState} from 'vuex'
  import { Input,  CurrencyConverterInput, CurrencyInput ,TelInput, Select} from "@/UI/Input";
  import { Button } from "@/UI/Button";
  import Spinner from '@/UI/Spinner'
  import {InlineToast} from '@/UI/Notification'
  import {LOOKUP_SWIFT} from '@/utils/api/wire';
  import countryData from '@/utils/sharedData/countryData.json'


    const initialData = {
      // bankId:'',
      swiftCode:'',
      country:'',
      accountNumber: '',
      accountName:'',
      email:'',
      phoneNumber:'',
      address:'',
      name:'',
      intlBankId:'',
      countrySpecificData:{},
      businessRegistrationNumber:'',
      financialSystemCode:'',
      id: null,
      city:"",
      postalCode:"",
      countryCode:'',
      userBank:""
    }

  export default {
    components: {
      Input,
      Button,
      CurrencyConverterInput,
      Spinner,
      CurrencyInput,
      InlineToast,
      TelInput,
      Select
    },
    props:{
      userData:{
        type: Object,
        default: () => {}
      },
      wireAccountData:{
            type: Object,
            default:() => {}
      },
      prefillbeneficiaryDetails:{
        type: Boolean,
        default: false,
        required: true
      }
    },
    data: () => ({
      convertFromValue: 0,
      swiftCode:'',
      swiftLookupLoading: false,
      country:'',
      userBank:'',
      currencyTo:'',
      currencySigns:{},
      paymentFirstData:{
        // bankId:'',
        swiftCode:'',
        country:'',
        accountNumber: '',
        accountName:'',
        email:'',
        phoneNumber:'',
        address:'',
        name:'',
        intlBankId:'',
        countrySpecificData:{},
        businessRegistrationNumber:'',
        financialSystemCode:'',
        postalCode:null,
        state:'',
        city:'',
        countryCode:'',
        userBank:"",
        bankName:""
      },
      isPhoneNumberValid: false,
      routingErrorText:'',
      timer:null,
      bankCountry:'',
      showTelInput: true
    }),
   
    computed:{
      ...mapState({
            // currencies: (state) => state?.dashboard?.dashboardData.currencyMappings,
            // walletBalance:(state) => state?.dashboard.dashboardData?.wallet
      }),
      disableButton(){
        return Object.values(this.rules).includes(false);
      },
      rules(){
        return{
          swiftCode: this.paymentFirstData.swiftCode &&  this.paymentFirstData.swiftCode.length > 6,
          country: !!this.paymentFirstData.country.length,
          userBank: !!this.paymentFirstData.userBank.length,
          // recieveAmount: this.paymentFirstData.receiveAmount >= 1000,
          accountNumber: (this.paymentFirstData.countrySpecificData && this.paymentFirstData.countrySpecificData.collectIban) ? this.paymentFirstData.accountNumber.length == this.paymentFirstData.countrySpecificData.ibanExpectedLength :  !!this.paymentFirstData.accountNumber,
          email: this.paymentFirstData.email.length ?    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                    this.paymentFirstData.email.trim()
                ) : true,
          phoneNumber: (() => {
            if(this.paymentFirstData.country){
              const countryDialCode = countryData[this.paymentFirstData.countryCode].dialCode;
              return !!this.isPhoneNumberValid && this.paymentFirstData.phoneNumber.toString().startsWith(countryDialCode);
            }else{
              return false
            }
          })(),
          // address: !!this.paymentFirstData.address.length && /^[A-Za-z0-9!@#$%^&*(),.?":{}|<> ]*$/.test(this.paymentFirstData.address),
          address: !!this.paymentFirstData.address.length,
          name: !!this.paymentFirstData.name.length,
          businessRegistrationNumber: this.paymentFirstData.countrySpecificData.collectBusinessRegistrationNumber ? this.paymentFirstData.businessRegistrationNumber.length == 10 : true,
          financialSystemCode: this.paymentFirstData.countrySpecificData.collectIfcs ? this.paymentFirstData.financialSystemCode.length == 11 : true,
          postalCode:(() => {
            if(this.paymentFirstData.country){
              const countryObject = countryData[this.paymentFirstData.countryCode];
              if(!countryObject.zip) return true;
              const countryZipRegex = countryObject.zip;
              const selectedState = countryObject.subs && countryObject.subs.find((sub) => sub.code == this.paymentFirstData.state)
              return (selectedState && selectedState != 'undefined' && selectedState.zip) ? 
              (new RegExp(`^${countryZipRegex}$`).test(this.paymentFirstData.postalCode) && new RegExp(`${selectedState.zip}`).test(this.paymentFirstData.postalCode)) 
              : 
              new RegExp(`^${countryZipRegex}$`).test(this.paymentFirstData.postalCode)
            }else{
              return false
            }
          })(),
          state: this.paymentFirstData.state && !!this.paymentFirstData.state.length || false,
          city: this.paymentFirstData.city && !!this.paymentFirstData.city.length || false
        }
      },
      getStateForSelectedCountry(){
        try {
            if(this.paymentFirstData.country.length){
            const countryObject = countryData[this.paymentFirstData.countryCode]
            return countryObject?.subs
            }else{
              return []
            }
        }catch(e) {
          console.log('error from getting state => ', e)
        }
      },
      generateCountryOptionList(){
        // if(countryData){
          return Object.keys(countryData).map((key) => {
            return {
              code : key,
              label: countryData[key].name,
            }
          })
        // }else{
        //   return []
        // }
      }
    },
    methods: {
      // ...mapActions('payments', ['setFirstPaymentData', 'getFees']),
      handleNextStep() {
        // this.setFirstPaymentData(this.paymentFirstData)
        this.$emit('resetBeneficiaryPreview')
        this.$emit("nextStep", {data:this.paymentFirstData, step: 'two'});
      },
      handleLookupSwift(){
        this.userBank = ''
        this.paymentFirstData.country = ''
        if(this.paymentFirstData.swiftCode.length >= 8){
            this.swiftLookupLoading = true;
            this.routingErrorText = ''
            LOOKUP_SWIFT(this.paymentFirstData.swiftCode)
              .then(({data}) => {
                // console.log(response, 'response')
                this.paymentFirstData.country = data.data.beneficiaryBankDetails.country.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
                this.bankCountry = data.data.beneficiaryBankDetails.country.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
                this.paymentFirstData.userBank = data.data.beneficiaryBankDetails.name;
                this.paymentFirstData.intlBankId = data.data.beneficiaryBankDetails.id;
                this.paymentFirstData.countrySpecificData = data.data.countrySpecificData;
                this.paymentFirstData.countryCode = data.data?.countrySpecificData?.countryCode;
                this.addPaymentDataWatcher()
                this.swiftLookupLoading = false
              }).catch((error) => {
                this.swiftLookupLoading = false
                if(error?.response?.data?.message){
                  this.routingErrorText = error?.response?.data?.message
                }else{
                  this.routingErrorText = 'Technical difficulties. Please, try again'
                }
              })
        }
    
      },
      getLookUpData(){
        let functionToCall;
        const showError = () => {
          this.routingErrorText = 'Incomplete digits'
        }
        if(this.paymentFirstData.swiftCode.length < 8){
          functionToCall = showError
        }else{
          functionToCall = this.handleLookupSwift
        }
        
        this.debounce(functionToCall, 1500)
      },
      debounce(func, time){
          if (this.timer) {
            clearTimeout(this.timer); // clear any pre-existing timer
          }
          // const context = this; // get the current context
          this.timer = setTimeout(() => {
            func(); // call the function if time expires
          }, time);
        },
      handleLookupSwiftCode(){
        this.handleLookupSwift()
        // this.debounce(functionToCall, 1000)
      },
      isPhoneValid(value) {
        this.isPhoneNumberValid = value;
      },
      // googleAutocomplete() {
      //   document.getElementById("address").placeholder = "Enter beneficiary's address";
       
      //   this.autocomplete = new google.maps.places.Autocomplete(
      //       document.getElementById("address"),
      //       // options
      //   );
      //   this.autocomplete.addListener("place_changed", () => {
      //       // this.isAddressValid = true;
      //       // this.validateAddress()
      //       let place = this.autocomplete.getPlace();
      //       // console.log(place);
      //       let ac = place.address_components;
      //           const street = {
      //           no: "",
      //           name: "",
      //           neighborhood: "",
      //       };

      //       this.paymentFirstData.address = place.formatted_address;
      //       });
      //   },
        handleLookupValidation(){
          if(this.paymentFirstData.swiftCode.length < 8){
            this.userBank = ''
            this.paymentFirstData.country = ''
            this.routingErrorText = 'Incomplete digits'
          }
        },
        setInititalValues(){
          if(this.prefillbeneficiaryDetails){
            Object.keys(this.paymentFirstData).forEach((key) => {
              if(this.userData.beneficiary[key] && this.userData.beneficiary[key]) this.paymentFirstData[key] = this.userData.beneficiary[key]
              this.paymentFirstData.swiftCode = this.userData.beneficiary.routingNumber || this.userData.beneficiary.swiftCode;
              if(this.userData?.beneficiary?.bankName){
                this.paymentFirstData.userBank = this.userData.beneficiary.bankName &&  this.userData.beneficiary.bankName;
              }
             if(this.userData.beneficiary?.countrySpecificData?.countryCode){
                this.paymentFirstData.countryCode = this.userData.beneficiary?.countrySpecificData?.countryCode && this.userData.beneficiary?.countrySpecificData?.countryCode;
              }
            })
            this.triggerInputValidations()
          }else{
             this.paymentFirstData = Object.assign({},initialData);
          }
          this.addPaymentDataWatcher()
        },
        triggerInputValidations(){
          this.$nextTick(() => {
            if(this.$refs.ifscInput)this.$refs.ifscInput.displayInput()
            if(this.$refs.businessRegInput)this.$refs.businessRegInput.displayInput()
            if(this.$refs.ibanInput)this.$refs.ibanInput.displayInput()
            // if(this.$refs.beneficiaryPhoneInput && !this.userData?.beneficiary?.phoneNumber){
            //   this.$refs.beneficiaryPhoneInput.handleValid(null);
            //   this.$refs.beneficiaryPhoneInput.isBlurred = true
            // }
            const countryDialCode = countryData[this.paymentFirstData.countryCode].dialCode;
            if((this.$refs.beneficiaryPhoneInput && !this.userData?.beneficiary?.phoneNumber) || !this.userData?.beneficiary?.phoneNumber.startsWith(countryDialCode)){
              // this.$refs.beneficiaryPhoneInput.handleValid(null);
              this.$refs.beneficiaryPhoneInput.isBlurred = true
            }
            if(!this.paymentFirstData.email && this.$refs.emailRef){
              this.$refs.emailRef.displayInput()
            }
            if(!this.paymentFirstData.postalCode && this.$refs.postalCodeInput){
              this.$refs.postalCodeInput?.displayInput()
            }
            if(!this.paymentFirstData.city && this.$refs.cityInput){
              this.$refs.cityInput?.displayInput()
            }
          })
        },
        addPaymentDataWatcher(){
          this.$watch('paymentFirstData.country', function(to, from) {
            if(to != from){
              this.paymentFirstData.phoneNumber = '';
              this.paymentFirstData.state = "";
              this.paymentFirstData.city = "";
              this.paymentFirstData.postalCode = ""
              this.showTelInput = false;
              setTimeout(() => {
                // this.paymentFirstData.countryCode = countryCode;
                this.showTelInput = true;
                // this.triggerInputValidations()
              })
            }
          })
        },
        handleSelectCountry(value){
          this.paymentFirstData.country = value.label;
          this.paymentFirstData.countryCode = value.code;
          setTimeout(() => {
            this.triggerInputValidations()
          },100)
        },
    },
    mounted(){
      this.setInititalValues()
  
    }
  };
</script>
<style scoped>
.bank-name{
  right: 1rem;
  top: 3.7rem;
  z-index: 5
}
</style>